import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import './gltf_scene.css'
function Model(props) {
  const { scene } = useGLTF("/Heroi_comemora.glb");
  return <primitive  object={scene} />;
}

const GltfScene = () => (
  <div className="canvas">
    <Canvas  pixelRatio={[1, 2]} camera={{ position: [-10, 15, 15], fov: 50 }}>
      <ambientLight intensity={2} />
      <Suspense  fallback={null}>
        <Model />
      </Suspense>
      <OrbitControls maxDistance={35}/>
    </Canvas>
    </div>
);

GltfScene.propTypes = {};

GltfScene.defaultProps = {};

export default GltfScene;
