import React from 'react';
import PropTypes from 'prop-types';

const MyComponent = () => (
 <>
    MyComponent Component
 </>
);

MyComponent.propTypes = {};

MyComponent.defaultProps = {};

export default MyComponent;
