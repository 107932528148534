import React from 'react';
import PropTypes from 'prop-types';
import './PageButton.css';
import '../../index.css';
import {  Link } from "react-router-dom";
const PageButton = (props) => (
  <Link to={props.Link} className='no-decoration'>
  <div className='flex col PageButton'>
    <img className='PageButton-img' src={props.img}/>
    <img className='PageButton-gif' src={props.gif}/>
    <div className='PageButton-text flex col align'>
      {props.Text}
   </div>
  </div>
  </Link>
);

PageButton.propTypes = {};

PageButton.defaultProps = {};

export default PageButton;
