import React from 'react';
import PropTypes from 'prop-types';
import './Menu.css'
import napalm from './images/napalm_logo.png'
import phone from './images/smartphone.png'
import cube from './images/cube.png'
import playCircle from './images/play-circle.png'
import gamepad from './images/gamepad.png'
import file from './images/File.png'
import '../../index.css'
import {  Link } from "react-router-dom";
const Mask = ({ mask }) => (
  
  <div className='menu-button-img' style={{
    
    WebkitMaskImage: mask,
    maskImage: mask,
    
    
  }}>

  </div>
)


const Menu = (props) => (

  <div className='Menu desktop'>
    <Link to="/" className='no-decoration'>
      <img className='logo' src={napalm}/>
    </Link>
    <div className='menu-button-container'>

      <Link to="/UnderConstruction" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Aprendizagem</div>
          <Mask mask={'url('+phone+')'}/>
        </div>
      </Link>
      
      <Link to="/UnderConstruction" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Realidade Virtual</div>
          <Mask mask={'url('+cube+')'}/>
        </div>
      </Link>

      <Link to="/UnderConstruction" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Animações 3D</div>
          <Mask mask={'url('+playCircle+')'}/>
        </div>
      </Link>

      <Link to="/UnderConstruction" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Jogos e Aplicativos</div>
          <Mask mask={'url('+gamepad+')'}/>
        </div>
      </Link>

      <Link to="/Cases" className='no-decoration'><div className='menu-button'>
        <div className='menu-button-text'>Cases</div>
          <Mask mask={'url('+file+')'}/>
        </div>
      </Link>
      
    </div>
  </div>
);

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;
