import React from 'react';
import PropTypes from 'prop-types';


const Footer = () => (
  <div>
    
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
